@import "~@/assets/scss/variables.scss";



















































































































































































































































.module-health-container {
  height: calc(100vh - 105px)
}
.module-health-navbar {
  background-color: #fcfcfc;
}
.multiselect {
  width: 250px;
}

.min-height-fit {
  min-height: fit-content;
}

.graph-container {
  flex-basis: 0;
  min-height: 315px;
}
