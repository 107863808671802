@import "~@/assets/scss/variables.scss";




































































































































































































































































































































.graph-container {
  flex-basis: 0;
  min-height: 300px;
  position: relative;
}
.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.chart-toggle {
  position: absolute;
  z-index: 100;
  left: 8px;
  top: 4px;
  font-size: 15px;
}
