@import "~@/assets/scss/variables.scss";



















































































































































































































.stc-table {
  white-space: nowrap;
  min-height: 360px;
}

.stc-table-loading {
  overflow-x: hidden;
}

.stc-table-loading-container {
  height: 60px;
}

.stc-table-loading-icon {
  left: 50%;
  position: absolute;
  transform: translateX(-30px);
}
